// write a function that takes a YYYY-MM-DD date string and formats it as MM.DD.YYYY. If the string is not a valid date, return the string as is.

export function formatDate(dateString: string): string {
  const date = new Date(dateString)
  if (isNaN(date.getTime())) {
    return dateString
  }
  // add a leading zero to single digit months and days
  const month =
    date.getMonth() + 1 < 10 ? `0${date.getMonth() + 1}` : date.getMonth() + 1
  const day = date.getDate() < 10 ? `0${date.getDate()}` : date.getDate()
  return `${day}.${month}.${date.getFullYear()}`
}
