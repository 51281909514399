import React, { useState } from "react"
import { useStaticQuery, graphql } from "gatsby"
import { GatsbyImage, getImage } from "gatsby-plugin-image"
import Input from "./items/Input"
import Textarea from "./items/Textarea"
import Button from "../common/Button"
import GridTwo from "../common/GridTwo"
import { H2, H3 } from "../typography/Headings"
import P from "../typography/P"
import Select from "./items/Select"
import Checkbox from "./items/Checkbox"

function FotoreisenAnfragen({ publikation, url }) {
  const [asGift, setAsGift] = useState(false)

  const data = useStaticQuery(graphql`
    {
      aboHinweis: file(relativePath: { eq: "VersandBlatt_ZweiJahresAbo.jpg" }) {
        id
        childImageSharp {
          gatsbyImageData(width: 1200)
        }
      }
    }
  `)

  const handleGift = e => {
    setAsGift(e.target.value)
  }
  return (
    <>
      <div className="max-w-2xl mb-5">
        <H2 className="mb-5">Einzelheft</H2>
        <P className="mb-5">
          Sie möchten kein Abo, sondern nur Einzelhefte unseres Magazins
          "Naturblick - Das Magazin für Natur und Naturfotografie" bestellen?
        </P>
        <Button
          to="/verlag/zeitschriften/einzelheftbestellung/"
          text="Zur Einzelheftbestellung"
        />
      </div>{" "}
      <div className="max-w-2xl">
        <H2 className="mb-5">Abonnement</H2>
        <H3 className="mb-5">
          Zweijahres-Abonnement der Zeitschrift NATURBLICK
        </H3>
        <GatsbyImage
          image={getImage(data.aboHinweis)}
          alt=""
          className="mb-4"
        />
        <P className="mb-2">
          Ab sofort gilt, dass jeder Neuabonnent (betrifft auch Geschenk- und
          Auslandsabo!) nach Abschluss eines Zweijahres-Abonnements der
          Zeitschrift NATURBLICK, neben der Zeitschrift und der
          NATURBLICK-Sonderausagebe, das NATURBLICK-Spezial „Mit Geduld und Biss
          – Ansitz und Tarnung in der Tierfotografie“ im Wert von 19,90 Euro als
          Geschenk erhält.
        </P>

        <P className="mb-2">
          Ein Zweijahres-Abo kostet 72,- Euro innerhalb Deutschlands und 90,-
          Euro im europäischen Ausland!
        </P>
        <P className="mb-2">
          Wir liefern Ihnen auch weiterhing die Zeitschrift NATURBLICK im
          Jahresabo, welche 4 Ausgaben pro Jahr umfasst, zum Jahrespreis von
          36,– Euro innerhalb Deutschlands bzw. 45,– Euro im europäischen
          Ausland direkt frei Haus. Das Abonnement beginnt mit der ersten
          Lieferung und kann nach dem ersten Bezugsjahr jederzeit beendet
          werden. Bei einer Kündigung erhalten Sie eine Rückerstattung für
          bereits bezahlte, aber noch nicht gelieferte Hefte.
        </P>
        <P className="mb-5">
          Mit unseren Abonnements entdecken und genießen Sie die Vielfalt und
          Schönheit der Natur durch qualitativ hochwertige Artikel und
          beeindruckende Fotografien.
        </P>
      </div>{" "}
      <form
        name="Zeitschriften Abonnement"
        method="POST"
        data-netlify="true"
        action="/anfrage-gesendet"
        className="space-y-4"
      >
        <input
          type="hidden"
          name="form-name"
          value="Zeitschriften Abonnement"
        />
        <input type="hidden" name="Zeitschrift" value={publikation} />
        <input type="hidden" name="Link" value={url} />
        <div className="max-w-2xl space-y-5">
          <Select
            label="Das Abonnement soll"
            id="fuer"
            name="Das Abonnement soll"
            required
            options={["für mich selbst sein", "ein Geschenk sein"]}
            onChange={handleGift}
          />
          <Select
            label="Abonnementlaufzeit"
            id="laufzeit"
            name="Abonnementlaufzeit"
            required
            options={["1 Jahr", "2 Jahre"]}
            onChange={handleGift}
          />
        </div>
        <GridTwo>
          <div className="space-y-5">
            <H3>Meine Anschrift (Rechnungsanschrift)</H3>
            <div className="grid grid-cols-1 gap-4 sm:grid-cols-2">
              <Input
                type="text"
                label="Vorname"
                id="vorname"
                name="Vorname"
                placeholder="Max"
                autoComplete="given-name"
                required
              />
              <Input
                type="text"
                label="Nachname"
                id="nachname"
                name="Nachname"
                placeholder="Mustermann"
                autoComplete="family-name"
                required
              />
            </div>
            <Input
              type="text"
              label="Straße"
              id="strasse"
              name="Straße"
              placeholder="Musterstraße 17"
              autoComplete="street-address"
              required
            />
            <Input
              type="text"
              label="PLZ"
              id="plz"
              name="PLZ"
              inputMode="numeric"
              placeholder="12345"
              autoComplete="postal-code"
              required
            />
            <Input
              type="text"
              label="Ort"
              id="ort"
              name="Ort"
              placeholder="Musterstadt"
              autoComplete="address-level2"
              required
            />
            <Input
              type="text"
              label="Land"
              id="land"
              name="Land"
              placeholder="Musterland"
              autoComplete="country-name"
              required
            />
            <Input
              type="email"
              label="E-Mail"
              id="email"
              name="E-Mail"
              placeholder="max@muster.de"
              autoComplete="email"
              required
            />
            <Input
              type="tel"
              label="Telefon"
              id="telefon"
              name="Telefon"
              placeholder="01234 567890"
              autoComplete="tel"
              inputMode="numeric"
            />
          </div>

          <div
            className={`space-y-5 ${
              asGift === "ein-geschenk-sein" ? "" : "hidden"
            }`}
          >
            <H3>Anschrift des Beschenkten</H3>
            <div className="grid-cols-2 gap-4 sm:grid ">
              <Input
                type="text"
                label="Vorname des Beschenkten"
                id="vorname-secondary"
                name="Vorname des Beschenkten"
              />
              <Input
                type="text"
                label="Nachname des Beschenkten"
                id="nachname-secondary"
                name="Nachname des Beschenkten"
              />
            </div>
            <Input
              type="text"
              label="Straße des Beschenkten"
              id="strasse-secondary"
              name="Straße des Beschenkten"
            />
            <Input
              type="text"
              label="PLZ des Beschenkten"
              id="plz-secondary"
              name="PLZ des Beschenkten"
              inputMode="numeric"
              autoComplete="postal-code"
            />
            <Input
              type="text"
              label="Ort des Beschenkten"
              id="ort-secondary"
              name="Ort des Beschenkten"
              autoComplete="address-level2"
            />
            <Input
              type="text"
              label="Land des Beschenkten"
              id="land-secondary"
              name="Land des Beschenkten"
              autoComplete="country-name"
            />
          </div>
        </GridTwo>

        <Textarea label="Kommentar" id="kommentar" name="Kommentar" />

        <div className="space-y-5">
          <Checkbox
            label="Datenschutzerklärung"
            id="datenschutz-einwilligung"
            name="Datenschutzerklärung"
            text="Es gilt der Datenschutz des Naturblick Verlags. Ihre persönlichen Daten benötigen wir zur Einrichtung und Verwaltung Ihres Abonnements. Ich stimme zu, dass meine Angaben aus dem Bestellformular zur Bearbeitung meiner Anfrage erhoben und verarbeitet werden. Die Daten werden nach abgeschlossener Bearbeitung Ihrer Anfrage gelöscht. Hinweis: Sie können Ihre Einwilligung jederzeit für die Zukunft per E-Mail an verlag@naturblick.com widerrufen."
            required
          />
          <Checkbox
            label="E-Mail-Marketing"
            id="e-mail-marketing-einwilligung"
            name="E-Mail-Marketing"
            text="Ich willige ein, dass mich Naturblick Verlag per E-Mail über die von ihm angebotenen Zeitschriften, Online-Angebote, Printprodukte, Veranstaltungen informiert. Meine Daten werden ausschließlich zu diesem Zweck genutzt. Eine Weitergabe an Dritte erfolgt nicht. Ich kann die Einwilligung jederzeit per E-Mail an info@naturblick.com widerrufen"
          />
        </div>

        <Button submit text="Anfrage senden" className="w-full" />
      </form>
    </>
  )
}

export default FotoreisenAnfragen
