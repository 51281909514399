import React from "react"
import { graphql } from "gatsby"
import { GatsbyImage, getImage } from "gatsby-plugin-image"
import Layout from "../components/layouts/Default"
import { H1 } from "../components/typography/Headings"
import Button from "../components/common/Button"
import Container from "../components/common/Container"
import GridTwo from "../components/common/GridTwo"
import {
  CalendarIcon,
  CurrencyEuroIcon,
  DocumentIcon,
} from "@heroicons/react/outline"
import "react-image-gallery/styles/css/image-gallery.css"
import ReactHtmlParser from "react-html-parser"
import ZeitschriftenAnfragen from "../components/forms/ZeitschriftenAnfragen"
import SpezialAnfragen from "../components/forms/SpezialAnfragen"
import SonderausgabenAnfragen from "../components/forms/SonderausgabenAnfragen"
import BuchAnfragen from "../components/forms/BuchAnfragen"
import KalenderAnfragen from "../components/forms/KalenderAnfragen"
import Breadcrumb from "../components/common/Breadcrumb"
import CtaSection from "../components/sections/CtaSection"
import Gallery from "../components/common/Gallery"
import { formatDate } from "../utils"

const umlautMap = {
  "\u00dc": "UE",
  "\u00c4": "AE",
  "\u00d6": "OE",
  "\u00fc": "ue",
  "\u00e4": "ae",
  "\u00f6": "oe",
  "\u00df": "ss",
}

function replaceUmlaute(str) {
  return str
    .replace(/[\u00dc|\u00c4|\u00d6][a-z]/g, a => {
      const big = umlautMap[a.slice(0, 1)]
      return big.charAt(0) + big.charAt(1).toLowerCase() + a.slice(1)
    })
    .replace(
      new RegExp("[" + Object.keys(umlautMap).join("|") + "]", "g"),
      a => umlautMap[a]
    )
}

export default function Page({ data, location }) {
  const {
    date,
    art,
    description,
    title,
    title_slug,
    pdf,
    price,
    remoteCoverImage,
    remoteTitleImage,
    disableForm,
    yumpu,
    epaper,
    galleryThumbnails,
    galleryImages,
  } = data.publikationen

  return (
    <Layout location={location} title={`${title} | Publikationen`} desc="">
      <div className="relative">
        <div className="absolute inset-0 h-full w-full">
          <GatsbyImage
            image={getImage(remoteTitleImage)}
            alt="Titel"
            className="absolute inset-0 h-full w-full"
          />
        </div>
        <div className="fluid relative z-10 items-center justify-center bg-black bg-opacity-50 py-16">
          <div className="mx-auto max-w-7xl p-6 text-center text-white">
            <span>Publikationen</span>
            <H1 display>{title}</H1>
          </div>
        </div>
      </div>
      <Breadcrumb
        pages={[
          {
            name: "Verlag",
            to: "/verlag/",
          },
          {
            name: `${art}`,
            to: `/verlag/${replaceUmlaute(art).toLowerCase()}/`,
          },
          {
            name: title,
            to: `/verlag/${replaceUmlaute(art).toLowerCase()}/${title_slug}/`,
          },
        ]}
      />
      <Container noPadding>
        {title_slug !== "sonderausgabe-2023" && (
          <CtaSection
            title="Sonderausgabe 2025"
            text="Mit NATURBLICK der Natur auf der Spur! Entdecken Sie unsere neue kostenloste Sonderausgabe direkt zum Runterladen."
            link="/verlag/sonderausgaben/sonderausgabe-2025/"
            linkText="Zur Sonderausgabe"
            image={data.sonderausgabe}
            location={location}
          />
        )}

        <GridTwo className="my-8">
          <div>
            <div className="prose">{ReactHtmlParser(description)}</div>
            {disableForm !== true && (
              <>
                {art === "Zeitschriften" && (
                  <div className="flex flex-col md:flex-row md:space-x-3">
                    <Button
                      anchor={`/verlag/${replaceUmlaute(
                        art
                      ).toLowerCase()}/${title_slug}/#anfragen`}
                      text="Zeitschrift abonnieren"
                      className="mt-5 block"
                    />
                    <Button
                      anchor={`/verlag/zeitschriften/einzelheftbestellung/`}
                      text="Einzelheft bestellen"
                      className="mt-5 block"
                    />
                  </div>
                )}
                {art === "Kalender" && (
                  <Button
                    anchor={`/verlag/${replaceUmlaute(
                      art
                    ).toLowerCase()}/${title_slug}/#anfragen`}
                    text="Kalender bestellen"
                    className="mt-5 block"
                  />
                )}
                {art === "Bücher" && (
                  <Button
                    anchor={`/verlag/${replaceUmlaute(
                      art
                    ).toLowerCase()}/${title_slug}/#anfragen`}
                    text="Buch bestellen"
                    className="mt-5 block"
                  />
                )}
                {art === "Spezial" && (
                  <Button
                    anchor={`/verlag/${replaceUmlaute(
                      art
                    ).toLowerCase()}/${title_slug}/#anfragen`}
                    text="Spezialausgabe bestellen"
                    className="mt-5 block"
                  />
                )}
                {art === "Sonderausgaben" && (
                  <Button
                    anchor={`/verlag/${replaceUmlaute(
                      art
                    ).toLowerCase()}/${title_slug}/#anfragen`}
                    text="Sonderausgabe bestellen"
                    className="mt-5 block"
                  />
                )}
              </>
            )}
          </div>
          <div>
            <GatsbyImage
              image={getImage(remoteCoverImage)}
              alt="Titel"
              className="mb-5"
            />

            <div className="space-y-6 border border-brand border-opacity-10 p-5">
              {price && (
                <div className="flex flex-row items-stretch">
                  <div className="flex items-center rounded bg-brand p-2 text-white">
                    <CurrencyEuroIcon className="h-8 w-8" />
                  </div>
                  <div className="ml-3 flex-1">
                    <div className="text-xs text-gray-700">Preis:</div>
                    {price}
                  </div>
                </div>
              )}
              {art && (
                <div className="flex flex-row items-stretch">
                  <div className="flex items-center rounded bg-brand p-2 text-white">
                    <DocumentIcon className="h-8 w-8" />
                  </div>
                  <div className="ml-3 flex-1">
                    <div className="text-xs text-gray-700">Art:</div>
                    {art}
                  </div>
                </div>
              )}
              {yumpu && (
                <div className="flex flex-row items-stretch">
                  <div className="flex items-center rounded bg-brand p-2 text-white">
                    <DocumentIcon className="h-8 w-8" />
                  </div>
                  <div className="ml-3 flex-1">
                    <div className="text-xs text-gray-700">Online lesen:</div>
                    <a
                      href={`${yumpu}`}
                      target="_blank"
                      rel="noreferrer"
                      className="underline"
                      download
                    >
                      Zur online Ausgabe
                    </a>
                  </div>
                </div>
              )}
              {epaper && (
                <div className="flex flex-row items-stretch">
                  <div className="flex items-center rounded bg-brand p-2 text-white">
                    <DocumentIcon className="h-8 w-8" />
                  </div>
                  <div className="ml-3 flex-1">
                    <div className="text-xs text-gray-700">Online lesen:</div>
                    <a
                      href={`${epaper}`}
                      target="_blank"
                      rel="noreferrer"
                      className="underline"
                      download
                    >
                      Zum E-Paper
                    </a>
                  </div>
                </div>
              )}
              {pdf && (
                <div className="flex flex-row items-stretch">
                  <div className="flex items-center rounded bg-brand p-2 text-white">
                    <DocumentIcon className="h-8 w-8" />
                  </div>
                  <div className="ml-3 flex-1">
                    <div className="text-xs text-gray-700">
                      Leseprobe PDF Download:
                    </div>
                    <a
                      href={`https://naturblick.privateserverconnection.de/storage/uploads${pdf.path}`}
                      target="_blank"
                      rel="noreferrer"
                      className="underline"
                      download
                    >
                      Leseprobe herunterladen{" "}
                      <span className="bg-gray-100 text-xs">
                        (ca. {Math.ceil(pdf.size / 1024 / 1024)} MB)
                      </span>
                    </a>
                  </div>
                </div>
              )}

              {date && (
                <div className="flex flex-row items-stretch">
                  <div className="flex items-center rounded bg-brand p-2 text-white">
                    <CalendarIcon className="h-8 w-8" />
                  </div>
                  <div className="ml-3 flex-1">
                    <div className="text-xs text-gray-700">Datum:</div>
                    {formatDate(date)}
                  </div>
                </div>
              )}
            </div>
          </div>
        </GridTwo>
        {/* {highlights && (
          <div>
            <div className="text-xs text-gray-700">Highlights:</div>
            <hr className="my-3 border-t border-gray-300" />
            <ul className="grid gap-3 md:grid-cols-2">
              {highlights.map((highlight, i) => {
                return (
                  <li key={i} className="flex flex-row items-stretch">
                    <div className="flex items-start text-green-500">
                      <CheckIcon className="w-6 h-6" />
                    </div>
                    <div className="flex-1 ml-3">{highlight.value}</div>
                  </li>
                )
              })}
            </ul>
          </div>
        )} */}
      </Container>

      {galleryImages && galleryImages.images.length > 0 && (
        <Container>
          <Gallery thumbnails={galleryThumbnails} images={galleryImages} />
        </Container>
      )}

      {disableForm !== true && (
        <Container>
          <div id="anfragen">
            {art === "Zeitschriften" && (
              <ZeitschriftenAnfragen
                publikation={title}
                url={`${location.href}`}
              />
            )}
            {art === "Kalender" && (
              <KalenderAnfragen publikation={title} url={`${location.href}`} />
            )}
            {art === "Bücher" && (
              <BuchAnfragen publikation={title} url={`${location.href}`} />
            )}
            {art === "Spezial" && (
              <SpezialAnfragen publikation={title} url={`${location.href}`} />
            )}
            {art === "Sonderausgaben" && (
              <SonderausgabenAnfragen
                publikation={title}
                url={`${location.href}`}
              />
            )}
          </div>
        </Container>
      )}
    </Layout>
  )
}

export const query = graphql`
  query ($id: String!) {
    publikationen(id: { eq: $id }) {
      date
      remoteCoverImage {
        childImageSharp {
          gatsbyImageData(
            transformOptions: { cropFocus: ATTENTION }
            width: 736
          )
        }
      }
      title
      title_slug
      art
      description
      disableForm
      pdf {
        title
        size
        path
        document
      }
      yumpu
      epaper
      price
      remoteTitleImage {
        childImageSharp {
          gatsbyImageData(
            transformOptions: { cropFocus: ATTENTION }
            width: 1600
          )
        }
      }
      galleryThumbnails: fields {
        images {
          localFile {
            childImageSharp {
              gatsbyImageData(width: 390)
            }
          }
        }
      }
      galleryImages: fields {
        images {
          localFile {
            childImageSharp {
              gatsbyImageData(width: 1600)
            }
          }
        }
      }
    }
    sonderausgabe: file(relativePath: { eq: "sonderausgabe_2025-cover.jpg" }) {
      childImageSharp {
        gatsbyImageData
      }
    }
  }
`
